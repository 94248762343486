import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import Coin from '../../icons/svg/Coin';
import { getFile } from '../../utils/system';
import { flex, breakpoints, color } from '../../utils/style';
import WithdrawForm from '../../components/Auth/Form/WithdrawForm';
import WithdrawPage from '../../components/Content/Withdraw/WithdrawPage';

const Wrapper = styled('div')`
  width: 100%;
  height: 625px;
  background-color: #0d182c;
  border-radius: 8px;
  
  ${flex};
  
  & .banner {
    width: 55%;
    height: 100%;
    position: relative;
    
    ${flex};
    
    & .gatsby-image-wrapper {
      width: 85%;
    }
    
    & .title {
      position: absolute;
      top: 44px;
      left: 40px;
      
      width: 100%;
      
      ${flex};
      justify-content: flex-start;
      
      & > span {
        margin-left: 40px;
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 44px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: ${color.blue};
      }
    }
  }
  
  & .form {
    width: 45%;
    height: 100%;
    background-color: ${color.darkPurple};
    border-radius: 0 8px 8px 0;
    padding: 50px 40px 0 40px;
  }
  
  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    height: max-content;
    background-color: transparent;
    
    & .banner {
      width: 100%;
      
      & .gatsby-image-wrapper {
        width: 100%;
      }
      
      & .title {
        left: 16px;
        top: 40px;
        
        & > svg {
          width: 40px;
          height: 40px;
        }
        
        & > span {
          font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;
          color: ${color.blue};
          margin-left: 15px;
        }
      }
    }
    
    & .form {
      width: 100%;
      margin-top: 25px;
      padding: 28px 14px 26px 14px;
    }
  }
`;

const Withdraw = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const { featured_media } = currentPage;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : `${currentPage.title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const { breadcrumbs } = pageContext;

  const banner = featured_media ?? { title: 'Withdraw Free Spin Money' };

  return (
    <Layout>
      <SEO title={metaTitle} description={metaDesc} pathname={currentPage.path} breadcrumbs={breadcrumbs} />
      <WithdrawPage />
    </Layout>
  );
};

export default Withdraw;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        alt_text
        title
        localFile {
          childImageSharp {
            fluid(maxWidth: 550) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
